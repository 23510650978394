<template>
  <base-list-view
    :dataSource="value"
    :headers="headers"
    isNested
    header-hidden
    hide-default-footer
    :show-actions="true"
    editable
    notShowSelect
    ref="listView"
    textCustomAdd="Добавить сотрудника"
    :show-add-in-table="true"
    @customHandle="hadlerClick"
    @customAdd="showDialog = true"
    :item-class="setItemClass"
    :disabledDelete="setItemClass"
    :custom-filter="customFilter"
    :search="search"
    hideEdit
  >
    <template slot="item.User.Picture" slot-scope="{ item }">
      <v-avatar style="background-color: var(--v-blue-grey-lighten2)">
        <v-img
          v-if="item.User.Picture"
          :src="getMediaByExternalId(item.User.Picture)"
        ></v-img>
        <span v-else>
          {{ item | PersonInitials }}
        </span>
      </v-avatar>
    </template>
    <select-employees
      :value="value"
      :showDialog.sync="showDialog"
      dialogTitle="Выбор сотрудников, которым добавится должность"
      button-save-text="Применить"
      @input="selectEmployee"
      :filterEmployees="filterEmployees"
    >
    </select-employees>
  </base-list-view>
</template>
<script>
import Vue from "vue";
import moment from "moment";

export default {
  components: {
    BaseListView: () => import("@/layouts/BaseListView.vue"),
    SelectEmployees: () =>
      import("@/components/organization/employees/SelectEmployees.vue"),
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    customFilter: {
      type: Function,
      default: null,
    },
    showPartition: {
      type: Boolean,
      default: true,
    },
    showPosition: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // todo: Разобраться
      search: "_",
      showDialog: false,
      employees: [],
      currentDate: moment().startOf("day").unix(),
      headers: [
        {
          text: "Фото",
          value: "User.Picture",
          align: "center",
          sortable: false,
          notGroup: true,
          notFilter: true,
        },
        {
          text: "Ф.И.О. сотрудника",
          value: "LastName",
          displayText: (e, obj) => this.$options.filters.PersonShortName(obj),
          width: "20%",
        },
        this.showPartition
          ? {
              text: "Подразделение",
              value: "AllPlaceName",
            }
          : null,
        this.showPosition
          ? {
              text: "Должность",
              value: "AllPositionName",
            }
          : null,
        {
          text: "Начало",
          value: "WorkPlacePeriod.DateIn",
          dataType: "Date",
          displayText: Vue.filter("DateShort"),
          align: "center",
        },
        {
          text: "Конец",
          value: "WorkPlacePeriod.DateOut",
          dataType: "Date",
          displayText: Vue.filter("DateShort"),
          align: "center",
        },
        {
          text: "Статус",
          value: "Status",
          dataType: "enum",
          options: ["Не активен", "Активен"],
          align: "center",
        },
      ].filter(Boolean),
    };
  },
  async mounted() {
    // this.employees = await this.LOAD_EMPLOYEES();
  },
  methods: {
    filterEmployees(item) {
      return !this.value.find(
        (v) =>
          v.Id === item.Id &&
          !(v?.WorkPlaceDateOut
            ? moment(v.WorkPlaceDateOut, "DD.MM.YYYY").unix() <=
              this.currentDate
            : false)
      );
    },
    isOverdue(item) {
      return (
        moment(item.WorkPlaceDateIn, "DD.MM.YYYY").unix() > this.currentDate ||
        moment(item.WorkPlaceDateOut, "DD.MM.YYYY").unix() <= this.currentDate
      );
    },
    setItemClass(item) {
      return !item.isNew && (!item.Status || this.isOverdue(item))
        ? "disabled"
        : "";
    },
    DataFromServerNormalize(data) {
      data.fio = this.$options.filters.PersonShortName(data);

      return data;
    },
    hadlerClick(e) {
      this.$router.push({
        name: "EmployeeEdit",
        params: { objectId: e.Id },
      });
    },
    selectEmployee(items) {
      for (let i = 0; i < items.length; i++) {
        const oldItem = this.value.find((e) => e.UserId === items[i].UserId);
        if (!oldItem) this.value.push({ ...items[i], isNew: true });
        else if (this.isOverdue(oldItem)) {
          oldItem.WorkPlaceDateOut = null;
          oldItem.WorkPlaceDateIn = moment().format("DD.MM.YYYY 00:00:00");

          oldItem.WorkPlaces = oldItem.WorkPlaces || [];
          const newWorkPlace = {
            Comment: null,
            DateIn: moment().format("DD.MM.YYYY 00:00:00"),
            DateOut: null,
            Id: -1,
            Place: null,
            Position: null,
            Schedule: null,
          };
          oldItem.WorkPlaces.push(newWorkPlace);
        }
      }
    },
  },
};
</script>
